<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="manualInvoiceDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Manual Invoice to HRDC </v-card-title>
        <v-card-text> Are you sure to submit ? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalManualInvoice()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="manualInvoiceDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-toolbar color="#063058">
        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center text-white">
            {{ model.add.name }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <span v-if="isTaxpodAdminRoles && !btnDisabled">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="hidden-xs-only"
                color="white"
                :loading="apiSubmit.isLoading"
                @click="invoiceActions('Submit')"
              >
                <v-icon dark v-bind="attrs" v-on="on">
                  mdi-cloud-upload
                </v-icon>
              </v-btn>
            </template>
            <span>Upload</span>
          </v-tooltip>
        </span>
      </v-toolbar>

      <div>
        <!-- HRDC Invoice File -->
        <v-col class="d-flex" cols="12" sm="12" md="12">
          <div
            v-cloak
            @drop.prevent="addDropFile($event)"
            @dragover.prevent
            class="w-100"
          >
            <v-file-input
              :prepend-icon="null"
              prepend-inner-icon="mdi-paperclip"
              counter
              multiple
              dense
              filled
              small-chips
              clearable
              :show-size="1000"
              label="HRDC Invoice File"
              accept=".pdf"
              v-model="formData.multipleInvoice"
              :error-messages="errorField.multipleInvoice"
              :truncate-length="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip close @click:close="deleteChip(index, text)">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </div>
        </v-col>
      </div>

      <v-col v-if="completeAttachment.length > 0">
        <v-alert outlined type="success" text dismissible>
          Success Uploaded: <br />
          <div v-for="(item, i) in completeAttachment" :key="i">{{ item }}</div>
        </v-alert>
      </v-col>

      <v-col v-if="failedAttachment.length > 0">
        <v-alert
          outlined
          type="error"
          text
          dismissible
          @input="onAlertDismissed()"
        >
          Failed Upload: <br />
          <div v-for="(item, i) in failedAttachment" :key="i">-{{ item }}</div>
        </v-alert>
      </v-col>

      <v-row class="pa-5 d-flex">
        <v-col cols="6">
          <v-expansion-panels focusable multiple v-model="panel">
            <v-expansion-panel
              v-for="(item, i) of formData.applicationManualInvoice"
              :key="i"
              width="500"
            >
              <v-expansion-panel-header disable-icon-rotate>
                <span class="font-weight-bold">
                  {{ item.companyName }}
                </span>
                <template v-slot:actions>
                  <v-tooltip v-if="item.isDuplicate" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon color="error" v-on="on">
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span>Same company name detected</span>
                  </v-tooltip>
                  <v-icon v-else-if="!item.isDuplicate" color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="pt-2">
                <v-row>
                  <v-col cols="10">
                    {{ name4 }} <br />
                    Price: {{ item.price }} <br />
                    Event Name: {{ item.programmeName }} <br />
                    Date (Day): {{ item.dateOfCourse }} <br />
                    Company Name: {{ item.companyName }} <br />
                    Grant No: {{ item.grantApprovalNo }} <br />
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-content-between align-end flex-column"
                  >
                    <v-checkbox
                      v-if="item.manualInvoiceToHrdcStatus"
                      :disabled="btnDisabled"
                      :input-value="true"
                      @change="
                        changeInvoiceStatus(item.applicationUuid, $event)
                      "
                    ></v-checkbox>
                    <v-checkbox
                      v-else
                      :disabled="btnDisabled"
                      @change="
                        changeInvoiceStatus(item.applicationUuid, $event)
                      "
                    ></v-checkbox>
                    <v-icon
                      style="cursor: pointer"
                      @click="copyContent(item)"
                      color="blue"
                      >mdi-content-copy</v-icon
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tax_summit/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    formattedDateOfCourse() {
      return {
        dateOfCourseStart: this.convertDateFormat(
          this.invoiceRequest.dateOfCourseStart
        ),
        dateOfCourseEnd: this.convertDateFormat(
          this.invoiceRequest.dateOfCourseEnd
        ),
      };
    },
  },
  props: ["params"],
  data: () => ({
    apiSubmit: new Api(),
    apiScanPdfText: new Api(),
    apiGetApplicationBulkManualInvoice: new Api(),
    apiUpdateManualInvoiceStatus: new Api(),
    apiGetApplicationManualInvoice: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    loadingDialog: false,
    formData: {
      applicationGrantsNo: {},
      multipleInvoice: [],
      applicationManualInvoice: [],
    },
    errorField: {
      applicationNo: null,
      clientNoXero: null,
      hrdcInvoiceNo: null,
      hrdcInvoiceFileUrl: null,
      invoiceDate: null,
      multipleInvoice: null,
    },
    manualInvoiceDialog: false,
    invoiceRequest: {
      companyName: null,
      programmeName: null,
      price: null,
      requestManualInvoiceAmount: null,
      grantId: null,
      dateOfCourseStart: null,
      dateOfCourseEnd: null,
    },
    btnDisabled: false,
    applicationManualInvoice: [],
    panel: [],
    completeAttachment: [],
    failedAttachment: [],
    bulkUploadStatus: false,
  }),
  async created() {
    this.loadingDialog = !this.loadingDialog;
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/manual_invoice/create-bulk-manual-invoice`,
        method: "post",
      },
      apiScanPdfText: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/manual_invoice/scan-pdf`,
        method: "post",
      },
      apiGetApplicationBulkManualInvoice: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-bulk-manual-invoice`,
        method: "post",
        params: {
          applicationUuid: this.formData.applicationNo,
          hrdcType: this.hrdcType,
        },
      },
      apiUpdateManualInvoiceStatus: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/manual_invoice/update-manual-invoice-status`,
        method: "post",
      },
      apiGetApplicationManualInvoice: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-manual-invoice`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].query !== undefined) {
        this[api].setQuery(apiDetails[api].query);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;

    this.rolesAssignation(this.auth.roles);
    if (!this.isTaxpodAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.apiGetApplicationBulkManualInvoice.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          // Check is there any active application need to apply manual invoice.
          if (data.length == 0) {
            this.alertError.push("No invoice need to be generate");
            this.btnDisabled = true;
            return;
          }

          // Append manual invoice data.
          for (const [key, item] of Object.entries(data)) {
            if (!item.manualInvoiceToHrdcStatus) {
              this.panel.push(Number(key));
            }

            let applicationManualInvoice = item.ApplicationLog.find(
              (item) =>
                item.stageId ==
                this.hrdcData.stagePriority.subscriptionDateScheduled
            );

            let invoiceData = {
              applicationUuid: item.uuid,
              companyName: item.oriCompanyName.toUpperCase(),
              programmeName: item.programmeName,
              price: item.totalPrice,
              requestManualInvoiceAmount: item.requestManualInvoiceAmount,
              grantApprovalNo: item.grantApprovalNo,
              manualInvoiceToHrdcStatus: item.manualInvoiceToHrdcStatus,
              dateOfCourse: `${this.convertDateFormat(
                applicationManualInvoice.startDate
              )} to ${this.convertDateFormat(
                applicationManualInvoice.endDate
              )}`,
              isDuplicate: item.isDuplicate,
            };

            this.formData.applicationManualInvoice.push(invoiceData);
            this.formData.applicationGrantsNo[item.grantApprovalNo] = item.uuid;
          }
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationBulkManualInvoice.fetch();
    this.loadingDialog = !this.loadingDialog;

    // Perform first check on user role, before enter (to prevent salesperson enter to unwanted view).
    this.roleMiddleware(this.auth.roles, this.service.key);
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    formatNumber(number) {
      if (number) {
        return number.toLocaleString();
      }
    },
    convertDateFormat(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD HH:mm:ssZ").format("D MMMM YYYY");
      }

      return "-";
    },
    changeInvoiceStatus(applicationUuid, isChecked) {
      this.loadingDialog = !this.loadingDialog;
      this.apiUpdateManualInvoiceStatus.setParams({
        applicationUuid,
        isChecked,
      });
      this.apiUpdateManualInvoiceStatus.fetch();
      this.apiUpdateManualInvoiceStatus.setCallbackCompleted((response) => {
        try {
          this.loadingDialog = !this.loadingDialog;
          const { status, data } = response;
          if (status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    },
    finalRequestAmount(price, requestManualInvoiceAmount) {
      let requestAmount = price;
      if (requestManualInvoiceAmount) {
        requestAmount = requestManualInvoiceAmount;
      }

      return `RM ${this.formatNumber(Number(requestAmount))}`;
    },
    normalizeSpaces(str) {
      return str.replace(/\u00A0/g, "\u0020");
    },
    copyContent(item) {
      const contentToCopy = `Event Name: ${item.programmeName}\nDate (Day): ${item.dateOfCourse}\nCompany Name: ${item.companyName}\nGrant No: ${item.grantApprovalNo}`;

      // Create a temporary textarea element
      const textarea = document.createElement("textarea");
      textarea.value = contentToCopy;
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      this.$store.dispatch("showMessage", {
        message: "Copied Successfully!",
        messageType: "info",
      });
    },
    // Drag & Drop file function.
    addDropFile(e) {
      let file = e.dataTransfer.files;

      for (const fileItem of file) {
        if (!fileItem.type.match("pdf.*")) {
          this.errorField.multipleInvoice = "Please select pdf file format";
          return;
        }

        this.formData.multipleInvoice.push(fileItem);
      }
    },
    scanPdf($event) {
      if ($event) {
        this.loadingDialog = !this.loadingDialog;
        const fd = new FormData();
        fd.append("hrdcInvoiceFileUrl", this.formData.hrdcInvoiceFileUrl);

        this.apiScanPdfText.setParams(fd);
        this.apiScanPdfText.fetch();
        this.apiScanPdfText.setCallbackCompleted((response) => {
          try {
            if (response.status) {
              this.formData.hrdcInvoiceNo = response.data.invoice;
              this.formData.clientNoXero = response.data.clientNo;

              const parsedDate = moment(
                response.data.invoiceDate,
                "DD MMMM YYYY"
              );
              const formattedDate = parsedDate.format("YYYY-MM-DD");
              this.formData.invoiceDate = formattedDate;

              this.loadingDialog = !this.loadingDialog;
            } else {
              this.loadingDialog = !this.loadingDialog;
              this.$store.dispatch("showMessage", {
                message: response.data,
                messageType: "error",
                timeout: 2000,
              });
            }
          } catch (err) {
            console.log(err);
          }
        });
      }
    },
    //EOC
    modalManualInvoice() {
      this.manualInvoiceDialog = !this.manualInvoiceDialog;
      this.submit();
    },
    invoiceActions(action) {
      if (this.bulkUploadStatus) {
        this.$store.dispatch("showMessage", {
          message: "Please refresh the page!",
          messageType: "info",
          timeout: 2000,
        });
        return;
      }

      switch (action) {
        case "Upload Invoice":
          break;

        case "Submit":
          // Clear all failed & complete attachment.
          this.completeAttachment = [];
          this.failedAttachment = [];

          // Check whether input file is empty or not.
          if (this.formData.multipleInvoice.length == 0) {
            this.errorField.multipleInvoice =
              "Please at least upload 1 attachment";
            return;
          }

          // Trigger submit function.
          this.submit();
          break;
      }
    },
    async submit() {
      this.loadingDialog = !this.loadingDialog;
      for (const invoice of this.formData.multipleInvoice) {
        // Generate Form Data to handle multipart/form-data
        const fd = new FormData();
        fd.append("userUuid", this.auth.uuid);
        fd.append("invoice", invoice);
        fd.append(
          "applicationGrantsNo",
          JSON.stringify(this.formData.applicationGrantsNo)
        );

        this.apiSubmit.setParams(fd);
        this.apiSubmit.setCallbackCompleted((response) => {
          try {
            this.clearErrorMsg();
            if (!response.status) {
              this.failedAttachment.push(
                `${response.invoiceFileName} (${response.matchError[0]})`
              );
              this.errorField.multipleInvoice = response.data;
              return;
            }

            if (response.status) {
              this.completeAttachment.push(
                `${response.invoiceFileName} (${response.companyName})`
              );
            }
          } catch (err) {
            console.log(err);
          }
        });

        await this.apiSubmit.fetch();
      }

      // Uploading process completed.
      this.panel = [];

      // Clear the file input to prevent spam/duplicate input.
      this.formData.multipleInvoice = [];

      // Flag for this upload process already complete, need to refresh page to proceed.
      this.bulkUploadStatus = true;

      this.loadingDialog = !this.loadingDialog;
    },
    onAlertDismissed() {
      // Clear all error messages.
      this.errorField.multipleInvoice = [];
    },
    deleteChip(index) {
      // Prompt here with text if required
      this.formData.multipleInvoice.splice(index, 1);
    },
  },
};
</script>
